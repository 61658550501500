<template>
  <div class="tableAdminBlockedAccount">
    <div class="cui__utils__heading mb-0">
      <strong>Rules</strong>
    </div>
    <div class="text-muted mb-3">
      On this page you can view & unblock user that has been blocked.
    </div>
    <hr />
    <a-table
      :columns="columns"
      :data-source="dataTable"
      :loading="loadingTable"
      :pagination="false"
      bordered
    >
      <div slot="action" slot-scope="text, record">
        <a-button :value="text" :loading="loadingDelete" @click.prevent="(e) => deleteBlockedAccount(e.target.value, record.key)" shape="round" type="danger">UNBLOCK</a-button>
      </div>
    </a-table>
  </div>
</template>

<script>
const columns = [
  {
    title: 'No',
    dataIndex: 'no',
    align: 'center',
    scopedSlots: { customRender: 'no' },
  },
  {
    title: 'IP Address',
    dataIndex: 'ipAddress',
    align: 'center',
    scopedSlots: { customRender: 'ipAddress' },
  },
  {
    title: 'Points',
    dataIndex: 'points',
    align: 'center',
    scopedSlots: { customRender: 'points' },
  },
  {
    title: 'Expire At',
    dataIndex: 'expire',
    align: 'center',
    scopedSlots: { customRender: 'expire' },
  },
  {
    title: 'User Name',
    dataIndex: 'userName',
    align: 'center',
    scopedSlots: { customRender: 'userName' },
  },
  {
    title: 'User ID',
    dataIndex: 'userID',
    align: 'center',
    scopedSlots: { customRender: 'userID' },
  },
  {
    title: 'Action',
    dataIndex: 'action',
    align: 'center',
    scopedSlots: { customRender: 'action' },
  },
]

export default {
  data() {
    return {
      columns,
      loadingTable: false,
      loadingDelete: false,
      dataTable: [],
    }
  },
  methods: {
    deleteBlockedAccount(value, key) {
      // console.log('value :>> ', value)
      // console.log('key :>> ', key)
      this.loadingDelete = true
      this.loadingTable = true
      this.$confirm({
        title: 'Warning',
        content: (
          <div>Are you sure to unblock this account? After unblocking, the user could login like usual</div>
        ),
        onOk: () => {
          this.$store.dispatch('admin/DELETE_BLOCKED_ACCOUNT', { key })
            .then(res => {
              // console.log('res :>> ', res)
              if (res) {
                this.$notification.success({
                  message: 'Success',
                  description:
                    'Account has been unblocked',
                })
              } else {
                this.$notification.error({
                  message: 'Error.',
                  description: 'Account cannot be unblocked',
                })
              }
              this.fetchData()
              this.loadingDelete = false
              this.loadingTable = false
            })
        },
        onCancel: () => {
          this.loadingTable = false
          this.loadingDelete = false
        },
        centered: true,
        icon: 'warning',
        okType: 'danger',
      })
    },
    fetchData() {
      this.loadingTable = true
      this.$store
        .dispatch('admin/GET_BLOCKED_ACCOUNT')
        .then((data) => {
          // console.log('fetchData', data)
          this.loadingTable = false
          this.dataTable = data
        })
    },
  },
  created() {
    this.fetchData()
  },
  computed: {
    isMobile() {
      return this.$store.getters.isMobile
    },
    isTablet() {
      return this.$store.getters.isTablet
    },
    isDesktop() {
      return !this.isMobile && !this.isTablet
    },
  },
}
</script>

<style lang="scss">
.tableAdminBlockedAccount {
  .ant-spin-nested-loading .ant-spin-container .ant-table {
    overflow-x: auto;
    table {
      @media (max-width: 769px) {
        width: auto;
      }
    }
  }
}
</style>
